<template>
  <div class="company-container">
    <div class="company-list-set">
      <div class="company-list-toolbar">
        <div class="toolbar-left">
          <h4
            class="section-title"
            v-if="title">
            {{title}}
            <!-- <span class="section-sub-title">共為您搜尋到 {{disputesCount}} 筆資料</span> -->
          </h4>
        </div>
        <div class="toolbar-right">
          <el-pagination
            layout="prev, pager, next, jumper"
            :total="companyCount"
            :current-page.sync="companysCurrentPage"
            @current-change="searchCompanyList">
          </el-pagination>
        </div>
      </div>
      <CompanyList
        style="min-height:100%"
        :companyList="currentCompanyData.list"
        :queryCondition="queryCondition"
        :isCollections="isCollections"
        v-loading="isLoading">
      </CompanyList>
      <div class="align-right">
        <el-pagination
          layout="prev, pager, next, jumper"
          :total="companyCount"
          :current-page.sync="companysCurrentPage"
          @current-change="searchCompanyList">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import * as apis from '@/apis/index.ts'
import { mapGetters } from 'vuex'
import CompanyList from '@/components/CompanyList/CompanyList.vue'

export default {
  props: {
    'title': {},
    'companys': {
      default () {
        return {
          list: [],
          stat: {}
        }
      }
    },
    'queryCondition': {
      required: true
    }
  },
  components: {
    CompanyList
  },
  data () {
    return {
      isLoading: false,
      currentCompanyData: {
        list: [],
        stat: {}
      },
      companysCurrentPage: 1,
      isCollections: {}
    }
  },
  computed: {
    ...mapGetters([
      'getterUserEmail'
    ]),
    companyCount () {
      return this.currentCompanyData.stat['total-count'] || 0
    }
  },
  methods: {
    // 查詢公司列表（切換頁碼）
    searchCompanyList (pageNo) {
      const searchRequest = {
        basic: JSON.parse(JSON.stringify(this.queryCondition.basic)),
        get: {
          companys: {
            'page-index': pageNo
          }
        }
      }
      this.isLoading = true
      apis.searchGet(searchRequest)
        .then((data) => {
          this.isLoading = false
          this.currentCompanyData = data.payload.companys
        })
        .catch(e => {
          this.isLoading = false
        })
    }
  },
  watch: {
    // 初次載入公司資料
    'companys': {
      handler (d) {
        this.currentCompanyData = JSON.parse(JSON.stringify(d))
      },
      immediate: true
    },
    // 目前公司資料
    'currentCompanyData': {
      handler (d) {
        if (d && d.list) {
          // 查詢收藏公司對應資料
          const uniIDs = d.list.map(d => d.uniID)
          if (!uniIDs.length) {
            return
          }
          const isCollectionsRequest = {
            "email": this.getterUserEmail,
            "companys": uniIDs
          }
          const isCollectionsResult = apis.isCollections(isCollectionsRequest)
            .then((data) => {
              this.isCollections = data.payload || {}
            })
        }
      },
      immediate: true
    }
  }
}
</script>
