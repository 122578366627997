export default [
  {
    label: '@Q@ api未提供',
    value: '@Q@ api未提供'
  },
  {
    label: '顯示全部判決',
    value: ''
  },
  {
    label: '只顯示刑事判決',
    value: '刑事判決'
  },
  {
    label: '只顯示民事判決',
    value: '民事判決'
  },
  {
    label: '只顯示行政判決',
    value: '行政判決'
  }
]
