<template>
  <div>
    <h4 class="section-title">
      {{title}}
      <span class="section-sub-title">共為您搜尋到 {{newsCount}} 筆資料</span>
      <span class="section-sub-title">資料來源：藍星球資訊</span>
    </h4>
    <div class="table-set">
      <div class="table-toolbar">
        <div class="toolbar-left">
          <el-date-picker
            v-model="startDate"
            type="date"
            placeholder="起始日期"
            size="mini"
            class="toolbar-input"
            value-format="yyyy-MM-dd"
            @change="search">
          </el-date-picker>
          <span class="connect">至</span>
          <el-date-picker
            v-model="endDate"
            type="date"
            placeholder="結束日期"
            size="mini"
            class="toolbar-input"
            value-format="yyyy-MM-dd"
            @change="search">
          </el-date-picker>
          <!-- @Q@ 暫先隱藏功能 -->
          <!-- <el-select class="toolbar-input" v-model="searchType" placeholder="搜尋方式" size="mini">
            <el-option
              v-for="item in searchTypeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
              @change="search">
            </el-option>
          </el-select>
          <el-tooltip class="item" effect="dark" content="@Q@ 其實我也不知道這個是什麼" placement="bottom">
            <font-awesome-icon :icon="['far', 'question-circle']" style="vertical-align:middle;margin-right: 4px;"/>
          </el-tooltip> -->
          <!-- <el-checkbox
            :true-label="1"
            :false-label="0"
            v-model="isRemoveDuplicate"
            @change="search">
            排除類似新聞
          </el-checkbox> -->
        </div>
      </div>
      <el-table
        :data="currentNews.list"
        v-loading="isLoading"
        empty-text="目前暫無資料喔 :)"
        highlight-current-row
        stripe>
        <el-table-column
          label="序號"
          type="index"
          align="center"
          width="56">
        </el-table-column>
        <el-table-column
          label="新聞標題"
          prop="title">
        </el-table-column>
        <el-table-column
          label="新聞來源"
          width="180"
          prop="media">
        </el-table-column>
        <el-table-column
          label="新聞日期"
          width="100">
          <template slot-scope="scope">
            {{returnNewsDate(scope.row.datetime_pub)}}
          </template>
        </el-table-column>
        <el-table-column
          label="新聞作者"
          width="180">
          <template slot-scope="scope">
            {{scope.row.author||'-'}}
          </template>
        </el-table-column>
        <!-- <el-table-column
          label="情緒分析"
          width="100"
          prop="score">
        </el-table-column> -->
        <el-table-column
          label="新聞全文"
          width="80">
          <template slot-scope="scope">
            <a
              target="_blank"
              :href="scope.row.url">
              查看全文
            </a>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="align-right">
      <el-pagination
        layout="prev, pager, next"
        :total="newsSearchableCount"
        :current-page.sync="currentPage"
        @current-change="search">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import * as apis from '@/apis/index.ts'
import * as codes from '@/const/codes.ts'
import * as config from '@/const/config.js'
import searchTypeOptions from '@/const/searchTypeOptions.js'

export default {
  props: {
    'title': {
      default () {
        return codes.COMPANY_DETAIL_ITEM_DICT.news
      }
    },
    'news': {
      required: true,
      default () {
        return {
          list: [],
          stat: {}
        }
      }
    },
    // case 公司查詢
    'uniID': {
      required: false
    },
    // case 人物查詢
    'field': {
      required: false
    },
    'keyword': {
      required: false
    }
  },
  data () {
    return {
      searchTypeOptions,
      isLoading: false,
      currentNews: {
        list: [],
        stat: {}
      },
      startDate: '',
      endDate: moment().format('YYYY-MM-DD'),
      searchType: '',
      currentPage: 1,
      isRemoveDuplicate: 1 // 1/0
    }
  },
  computed: {
    newsCount () {
      if (this.currentNews.stat['total-count']) {
        return this.currentNews.stat['total-count']
      } else {
        return 0
      }
    },
    newsSearchableCount () {
      if (this.currentNews.stat['total-count']) {
        if (this.currentNews.stat['total-count'] > config.SEARCH_MAX_COUNT) {
          return config.SEARCH_MAX_COUNT
        } else {
          return this.currentNews.stat['total-count']
        }
      } else {
        return 0
      }
    },
    // currentNewsList () {
    //   if (this.currentNews.list.length && this.judgmentType) {
    //     return this.currentNews.list.filter(d => {
    //       return d.type === this.judgmentType
    //     })
    //   } else {
    //     return this.currentNews.list
    //   }
    // }
  },
  methods: {
    returnNewsDate (date) {
      return date ? moment(date).format('YYYY-MM-DD') : '-'
    },
    search () {
      let searchRequest = {
        get: {
          news: {
            // 'remove-duplicate': this.isRemoveDuplicate,
            'page-index': this.currentPage,
            time: {
              
            }
          }
        },
        uniID: this.uniID
      }
      if (this.startDate) {
        searchRequest.get.news.time.start = this.startDate
      }
      if (this.endDate) {
        searchRequest.get.news.time.end = this.endDate
      }
      // case 公司查詢
      if (this.uniID != null) {
        searchRequest.uniID = this.uniID
        this.isLoading = true
        apis.companyGet(searchRequest)
          .then((data) => {
            this.isLoading = false
            if (data.payload.news) {
              this.currentNews = data.payload.news
            }
          })
          .catch(e => {
            this.isLoading = false
          })
      }
      // case 人物查詢
      if (this.field != null) {
        searchRequest.basic = {
          field: this.field,
          keyword: this.keyword
        }
        this.isLoading = true
        apis.searchGet(searchRequest)
          .then((data) => {
            this.isLoading = false
            if (data.payload.news) {
              this.currentNews = data.payload.news
            }
          })
          .catch(e => {
            this.isLoading = false
          })
      }
      
      
    }
  },
  watch: {
    'news': {
      handler (d) {
        if (d) {
          this.currentNews = JSON.parse(JSON.stringify(d))
        }
      },
      immediate: true
    }
  }
}
</script>
