export default [
  {
    label: '@Q@ api未提供',
    value: '@Q@ api未提供'
  },
  {
    label: '精確搜尋',
    value: '精確搜尋'
  },
  {
    label: '糊模搜尋',
    value: '糊模搜尋'
  }
]
