<template>
  <li class="company-item">
    <!-- 收藏按鈕slot -->
    <slot name="collectionButton" :company="company">
    </slot>
    <div class="company-item-header">
      <router-link :to="routerLinkTo">
        <div class="company-name">
          <!-- <span class="color-warning">宜誠</span>資訊股份有限公司 -->
          {{company.company_name}}
        </div>
      </router-link>
      <el-tag
        size="mini"
        type="success"
        v-if="isCompanyOperating">
        營業中
      </el-tag>
      <el-tag
        size="mini"
        type="danger"
        v-else>
        非營業中
      </el-tag>
      <!-- 符合查詢關鍵字的說明 -->
      <component
        :is="matchConditionComponent"
        :queryCondition="queryCondition"
        :company="company">
      </component>
      <!-- <div style="font-size: 12px;">[ 劉楊維 ] 是 <span class="color-warning">代表人</span>, <span class="color-warning">代表人</span></div> -->
    </div>
    <div class="company-item-container">
      <el-row>
        <el-col :span="18">
          <el-row :gutter="16">
            <el-col :span="14">
              <ul>
                <li>統一編號：{{company.uniID}}</li>
                <li>負責人：{{company.representative_name}}</li>
                <li>登記機關：{{company.registration_department}}</li>
                <li>登記現況：{{company.company_state}}</li>
                
              </ul>
            </el-col>
            <el-col :span="10">
              <ul>
                <li>核准設立日期：{{company.date_approved}}</li>
                <li>核准變更日期：{{company.date_approved_latest}}</li>
                <li>電話：{{companyPhone}}</li>
                <li>地址：{{company.company_address}}</li>
                <!-- <li>營業狀態：{{company.company_operate}}</li> -->
                <!-- <li>資本總額：{{company.capital_amount | emptyFormat | numberWithComma}}</li> -->
              </ul>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="6">
          <div class="custom-tag round-tag">
            <el-tag
              v-for="(userLabel,index) in currentUserLabels"
              :key="index"
              closable
              size="small"
              @close="deleteUserLabel(userLabel)">
              {{userLabel.label}}
            </el-tag>
            <el-input
              v-model="addingUserLabel"
              v-if="addingUserLabelVisible"
              ref="userLabelInput"
              @keyup.enter.native="saveAddingUserLabel"
              @blur="saveAddingUserLabel"
              size="small">
            </el-input>
            <el-button
              v-else
              class="btn-add-new-tag"
              size="small"
              round
              @click="activeUserLabelInput">
              + 新增標籤
            </el-button>
          </div>
        </el-col>
      </el-row>
    </div>
  </li>
</template>

<script>
import { mapGetters } from 'vuex'
import utils from '@/utils/utils.js'
import MatchPersonCondition from './MatchPersonCondition.vue'
import * as apis from '@/apis/index.ts'

export default {
  props: {
    'company': {
      required: true,
      type: Object
    },
    'queryCondition': {
      required: false,
      type: Object
    }
  },
  components: {
    MatchPersonCondition
  },
  data () {
    return {
      currentUserLabels: [],
      addingUserLabel: '',
      addingUserLabelVisible: false
      // business_matters: 'D101011↵發電業↵↵D101071↵輸配電業↵↵D101081↵公用售電業↵↵E601010↵電器承裝業↵↵IZ12010↵人力派遣業↵↵E603010↵電纜安裝工程業↵↵E603050↵自動控制設備工程業↵↵E604010↵機械安裝業↵↵IG02010↵研究發展服務業↵↵IG03010↵能源技術服務業↵↵I103060↵管理顧問業↵↵C901990↵其他非金屬礦物製品製造業↵↵F199010↵回收物料批發業↵↵H701010↵住宅及大樓開發租售業↵↵H701020↵工業廠房開發租售業↵↵JE01010↵租賃業↵↵G202010↵停車場經營業↵↵H703100↵不動產租賃業↵↵JZ99050↵仲介服務業↵↵G801010↵倉儲業↵↵JA02051↵度量衡器修理業↵↵I401010↵一般廣告服務業↵↵J901020↵一般旅館業↵↵G901011↵第一類電信事業↵↵G406061↵商港區船舶貨物裝卸承攬業↵↵F401190↵天然氣輸入業↵↵F399040↵無店面零售業↵↵F399990↵其他綜合零售業↵↵F299990↵其他零售業'
      // capital_amount: 400000000000
      // company_address: '臺北市中正區羅斯福路3段242號'
      // company_area: '臺北市_中正區'
      // company_county: '臺北市_中正區'
      // company_name: '台灣電力股份有限公司'
      // company_name_en: 'TAIWAN POWER COMPANY'
      // company_name_en_MOEA: 'TAIWAN POWER COMPANY'
      // company_operate: '營業中'
      // company_state: '核准設立'
      // data_type: '公司基本資料'
      // date_approved: '1950-12-14'
      // date_approved_latest: '2019-07-25'
      // date_closed_from: null
      // date_closed_to: null
      // etax_matters: '<aa>351000<bb>'
      // man_related: '<aa>楊偉甫<bb>,<aa>鍾炳利<bb>,<aa>林法正<bb>,<aa>張添晉<bb>,<aa>方良吉<bb>,<aa>許志義<bb>,<aa>劉啓群<bb>,<aa>劉佩玲<bb>,<aa>林子倫<bb>,<aa>江雅綺<bb>,<aa>鄭英圖<bb>,<aa>莊銘池<bb>,<aa>彭繼宗<bb>,<aa>廖展平<bb>,<aa>陸德勝<bb>'
      // managers: [{name: '<aa>鍾炳利<bb>', arrive: '105年09月02日'}]
      // multi_vote_stocks: -1
      // paidup_capital_amount: 330000000000
      // public_issue2: 'expire'
      // recent_export_performance: null
      // recent_import_performance: null
      // recent_procurement_amount: -1
      // registration_department: '經濟部商業司'
      // representative_name: '楊偉甫'
      // shareholders: [{name: '<aa>楊偉甫<bb>', stocks: '31032566507', type: '董事長', ROL: '經濟部'},…]
      // special_power: ''
      // stock_state: ''
      // uniID: '03795904'
      // uniID_parent: '03795904'
      // veto_power_stocks: -1
    }
  },
  computed: {
    ...mapGetters([
      'getterUserEmail'
    ]),
    // 動態component - 符合查詢關鍵字的說明
    matchConditionComponent () {
      if (this.queryCondition && this.queryCondition.basic) {
        // 搜尋選擇「人物」
        if (this.queryCondition.basic.keyword_type === 'owner' || // 單一公司搜尋選擇「人名」
        this.queryCondition.basic.field === 'all') { // 人物搜尋
          return 'MatchPersonCondition'
        }
        return null
      } else {
        return null
      }
    },
    companyPhone () {
      if (this.company.multi && this.company.multi.phone && this.company.multi.phone.length) {
        return this.company.multi.phone[0].value || '-'
      } else {
        return '-'
      }
    },
    companyEmail () {
      if (this.company.multi && this.company.multi.email && this.company.multi.email.length) {
        return this.company.multi.email[0].value || '-'
      } else {
        return '-'
      }
    },
    isCompanyOperating () {
      if (this.company.company_operate === '營業中') {
        return true
      }
      return false
    },
    routerLinkTo () {
      return {
        name: 'companyDetail',
        query: {
          uniid: this.company.uniID
        }
      }
    },
    userLabels () {
      return this.company['user_label'] || []
    }
  },
  methods: {
    // formatNumber (number) {
    //   if (number == null) {
    //     return '-'
    //   } else {
    //     return utils.formatCommaNumber(number)
    //   }
    // },
    activeUserLabelInput () {
      this.addingUserLabelVisible = true
      this.$nextTick(_ => {
        this.$refs.userLabelInput.$refs.input.focus()
      })
    },
    saveAddingUserLabel () {
      if (!this.addingUserLabel) {
        this.addingUserLabelVisible = false
        this.addingUserLabel = ''
        return
      }
      apis.companyEditLabel({
        uniID: this.company.uniID,
        label: this.addingUserLabel,
        user: this.getterUserEmail,
        status: 'self'
      }).then(d => {
          this.currentUserLabels.push({
            label: this.addingUserLabel,
            user: this.getterUserEmail,
            status: 'self'
          })
          this.addingUserLabelVisible = false
          this.addingUserLabel = ''
        })
      
    },
    deleteUserLabel (userLabel) {
      apis.companyEditLabel({
        uniID: this.company.uniID,
        label: userLabel.label,
        user: this.getterUserEmail,
        status: 'close'
      }).then(d => {
          this.currentUserLabels = this.currentUserLabels
            .filter(d => d.label !== userLabel.label)
          
          this.addingUserLabelVisible = false
          this.addingUserLabel = ''
        })
    }
  },
  watch: {
    userLabels: {
      handler (userLabels) {
        this.currentUserLabels = JSON.parse(JSON.stringify(userLabels))
      },
      immediate: true
    }
  }
}
</script>
