<template>
  <div>
    <div style="font-size: 12px;" v-if="keyword&&matchTypes">
      [ {{keyword}} ] 是 <span class="color-warning">{{matchTypes}}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: ['queryCondition', 'company'],
  data () {
    return {
    }
  },
  computed: {
    keyword () {
      if (this.queryCondition && this.queryCondition.basic && this.queryCondition.basic.keyword) {
        return this.queryCondition.basic.keyword
      } else {
        return ''
      }
    },
    matchTypes () {
      let matchArr = []
      if (this.queryCondition && this.queryCondition.basic && this.company && this.keyword) {
        // 搜尋選擇「人物」
        if (this.queryCondition.basic.keyword_type === 'owner' || // 單一公司搜尋選擇「人名」
        this.queryCondition.basic.field === 'all') { // 人物搜尋
          if (this.keyword === this.company.representative_name) {
            matchArr.push('代表人')
          }
          this.company.shareholders.forEach(d => {
            if (this.keyword === d.name) {
              matchArr.push(d.title) // 董監事的職稱
            }
          })
          if (this.company.managers.some(d => this.keyword === d.name)) {
            matchArr.push('經理人')
          }
        }      
      }

      return matchArr.join('、')
    }
  }
}
</script>

